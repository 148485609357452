<template>
  <div class="container justify-center items-center">
    
      <img
       style="max-width:100px ;"
                alt="login"
                class="mx-auto mt-3"
        src="@/assets/images/footer_logo.png"
      />
    <div>
      <div class="text-center">
        <h2 class="m-5 P-Text">{{ $t("OnlineAppointmentJoinAlert") }}</h2>

        <div class="P-Text">
          <u style="color: white" class="m-1" @click.stop="$emit('closePop')"
            >{{ $t("Back") }}
          </u>

          <vs-button
            class="m-1"
            color="white"
            style="width: 20%; text-align: center"
            @click="JoinSession()" 
          >
            <p style="color: #004477; font-weight: bold">{{ $t("Join") }}</p>
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { domain } from "@/gloabelConstant.js";
import modulePatient from "@/store/Patient/modulePatient.js";
export default {
  data() {
    return {
      baseURL: domain,
    };
  },
  props: {
    sessionModel: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
   
    JoinSession() {
      debugger;
      this.sessionModel;
      window.open(this.sessionModel.JoinUrl, "_blank");
      this.$emit("joinSession",this.sessionModel.ID);
      this.$emit('closePop');
    },
  },
  created(){
   if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
  },
  mounted() {
    console.log(this.data);
  },
};
</script>
<style>
.container {
  background: 
      radial-gradient(closest-side at 50% 50%, #004477 0%, #004477 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px;
  opacity: 1;
  text-align: center;
  width: 85%;
}
.container {
  text-align: center;
}
.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  display: inline-block;
  padding: 0.8rem;
  margin: 1%;
}
.TextLogo {
  letter-spacing: 0px;
  font-size: 2rem;
  font-weight: bold;
  color: #004477;
}
.P-Text {
  color: #ffffff;
  text-align: center !important    ;
  font-size: 1.2rem;
  letter-spacing: 0px;
  opacity: 1;
  margin-left: 2rem;
}
</style>
