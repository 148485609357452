<template>
  <div id="sessionDetail" class="w-full">
    <div class="vx-row">
      <feather-icon
        @click="back()"
        :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
        style="width: 30px
          display: inline;
          background-color: transparent !important;
          color: gray;
        "
      >
      </feather-icon>
      <p class="text-xl">
        {{ $t("DoctorSessionDetails") }}
      </p>
    </div>
    <div class="vx-row mt-5">
      <div class="lg:w-1/3"></div>
      <div class="lg:w-1/3">
        <div class="lg:w-1/2" v-if="Model.Patient.ProfileImagePath">
          <imageLazy
            :imageStyle="{
              'border-radius': '40px',
              width: '80px',
              height: '80px',
              size: '100px',
            }"
            :imageClass="'doctorImage'"
            :src="baseURL + Model.Patient.ProfileImagePath"
            placeHolderType="patient"
          />
          <div>
            <h1 v-if="Model.Patient" style="color: black; font-size: 30px">
              {{ Model.Patient.Name }}
            </h1>
          </div>
        </div>
        <div class="vx-col lg:w-1/2"></div>
      </div>

      <div class="vx-col lg:w-1/3" style="font-size: large">
        <div>
          <u @click="showPatientHistory(Model.PatientID)">
            {{ $t("PatientMedicalHistory") }}</u
          >
        </div>
        <div>
          <u @click="showDownloadReports(Model.PatientID, Model.DoctorID)">
            {{ $t("PatientMedicalReports") }}</u
          >
        </div>
        <div>
          <u @click="openPatientsSymptoms(Model.ID)">{{
            $t("PatientsSymptomsDescription")
          }}</u>
        </div>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col lg:w-3/4">
        <!-- 1 -->
        <div>
          <span class="warning mt-5">{{ $t("EditNote") }}</span>

          <div class="vx-row w-full mb-3 mt-5">
            <div style="color: #727272" class="vx-col lg:w-2/3">
              <span>{{ $t("DoctorsNotes") }}</span>
            </div>
            <div class="vx-col lg:w-1/4">
              <feather-icon
                class="cursor-pointer"
                :style="{ color: editDoctorNote ? '#004477' : 'red' }"
                icon="EditIcon"
                @click.stop="editDoctorNote = !editDoctorNote"
              ></feather-icon>
              <feather-icon
                style="color: primary; margin-left: 10px"
                icon="SaveIcon"
                :style="{ color: editDoctorNote ? 'red' : '#004477' }"
                @click.stop="SaveDoctorNotePatientDiagnosisNote()"
                class="cursor-pointer"
                v-show="editDoctorNote"
              >
              </feather-icon>
            </div>
          </div>

          <vs-textarea
            :disabled="!editDoctorNote"
            v-model="Model.DoctorComment"
            height="30%"
            style="
              border-radius: 16px;
              opacity: 1;
              background: #fefefe 0% 0% no-repeat padding-box;
              width: 80%;
            "
          >
          </vs-textarea>
        </div>

        <!-- 2 -->
        <div>
          <div class="vx-row w-full mb-3">
            <div style="color: #727272" class="vx-col lg:w-2/3">
              <span>{{ $t("Diagnosis") }}</span>
            </div>
            <div class="vx-col lg:w-1/4">
              <feather-icon
                :style="{ color: editPatientDiagnosis ? '#004477' : 'red' }"
                icon="EditIcon"
                @click.stop="editPatientDiagnosis = !editPatientDiagnosis"
                class="cursor-pointer"
              ></feather-icon>
              <feather-icon
                style="color: primary; margin-left: 10px"
                :style="{ color: editPatientDiagnosis ? 'red' : '#004477' }"
                icon="SaveIcon"
                @click.stop="SavePatientDiagnosis()"
                class="cursor-pointer"
                v-show="editPatientDiagnosis"
              >
              </feather-icon>
            </div>
          </div>

          <vx-card
            class="mb-3"
            height="70%"
            style="
              border-radius: 16px;
              opacity: 1;
              background: #fefefe 0% 0% no-repeat padding-box;
              width: 80%;
            "
          >
            <div class="vx-row">
              <v-select
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                class="w-full lg:w-1/4"
                :disabled="!editPatientDiagnosis"
                label="Name"
                v-model="DiagnosModel.Diagnos"
                :options="Diagnosis"
                placeholder="Diagnosis"
                :reduce="(obj) => obj"
              />
              <vs-button color="wight" :disabled="!editPatientDiagnosis">
                <feather-icon
                  style="margin-left: 10px; color: black"
                  icon="PlusIcon"
                  @click.stop="AddPatientDiagnosis()"
                  class="cursor-pointer"
                  v-show="editPatientDiagnosis"
                >
                </feather-icon>
              </vs-button>
            </div>

            <div
              style="text-decoration-line: underline"
              v-if="
                Model.PatientReservationSessionDiagnosis != null &&
                Model.PatientReservationSessionDiagnosis != undefined
              "
            >
              <div
                v-for="(
                  item, index
                ) in Model.PatientReservationSessionDiagnosis"
                :key="item.ID"
              >
                <div class="vx-row">
                  <h2
                    class="vx-col lg:w-1/2"
                    style="color: #454a62; font-size: 19px"
                  >
                    {{ item.Diagnosis.Name }}
                  </h2>
                  <vs-button color="wight" :disabled="!editPatientDiagnosis">
                    <feather-icon
                      style="margin-left: 10px; color: black"
                      icon="XIcon"
                      @click.stop="deletePatientDiagnosis(index)"
                      class="cursor-pointer"
                    ></feather-icon>
                  </vs-button>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <!-- 4 -->

        <div>
          <div class="vx-row w-full mb-3">
            <div style="color: #727272" class="vx-col lg:w-2/3">
              <span>{{ $t("DiagnosisNote") }}</span>
            </div>
            <div class="vx-col lg:w-1/4">
              <feather-icon
                :style="{ color: editPatientDiagnosisNote ? '#004477' : 'red' }"
                icon="EditIcon"
                @click.stop="
                  editPatientDiagnosisNote = !editPatientDiagnosisNote
                "
                class="cursor-pointer"
              ></feather-icon>
              <feather-icon
                style="color: primary; margin-left: 10px"
                :style="{ color: editPatientDiagnosisNote ? 'red' : '#004477' }"
                icon="SaveIcon"
                @click.stop="SaveDoctorNotePatientDiagnosisNote()"
                class="cursor-pointer"
                v-show="editPatientDiagnosisNote"
              ></feather-icon>
            </div>
          </div>

          <vs-textarea
            v-model="Model.DiagnosisNote"
            height="30%"
            class="m-9"
            :disabled="!editPatientDiagnosisNote"
            style="
              border-radius: 16px;
              opacity: 1;
              background: #fefefe 0% 0% no-repeat padding-box;
              width: 80%;
            "
          >
          </vs-textarea>
        </div>

        <!-- 3 -->

        <div>
          <div class="vx-row w-full mb-3">
            <div style="color: #727272" class="vx-col lg:w-2/3">
              <span>{{ $t("Furtherrequestedscansanalysis") }}</span>
            </div>
            <div class="vx-col lg:w-1/4">
              <feather-icon
                :style="{ color: editDoctorRequest ? '#004477' : 'red' }"
                icon="EditIcon"
                @click.stop="editDoctorRequest = !editDoctorRequest"
                class="cursor-pointer"
              ></feather-icon>
              <feather-icon
                style="color: primary; margin-left: 10px"
                :style="{ color: editDoctorRequest ? 'red' : '#004477' }"
                icon="SaveIcon"
                @click.stop="SaveDoctorRequest()"
                class="cursor-pointer"
                v-show="editDoctorRequest"
              ></feather-icon>
            </div>
          </div>

          <vx-card
            height="70%"
            class="mb-3"
            style="
              border-radius: 16px;
              opacity: 1;
              background: #fefefe 0% 0% no-repeat padding-box;
              width: 80%;
            "
          >
            <div class="vx-row">
              <div class="vx-col text-center lg:w-1/2 w-full">
                <v-select
                  :disabled="!editDoctorRequest"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full mb-4"
                  label="Name"
                  v-model="DoctorRequestModel.DocumentID"
                  :options="DocumentTypes"
                  :reduce="(ID) => ID.ID"
                  :placeholder="$t('DocumentType')"
                />
              </div>
              <div
                class="vx-col text-center lg:w-1/2 w-full"
                v-if="DoctorRequestModel.DocumentID == 1"
              >
                <v-select
                  :disabled="!editDoctorRequest"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full mb-10"
                  label="Name"
                  v-model="DoctorRequestModel.ScanTypeID"
                  :options="ScanTypes"
                  :reduce="(ID) => ID.ID"
                  :placeholder="$t('ScanType')"
                />
              </div>
              <div
                class="vx-col lg:w-1/2 w-full text-center"
                v-if="DoctorRequestModel.DocumentID == 2"
              >
                <v-select
                  :disabled="!editDoctorRequest"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full mb-10"
                  label="Name"
                  v-model="DoctorRequestModel.AnalysisTypeID"
                  :options="AnalysisTypes"
                  :reduce="(ID) => ID.ID"
                  :placeholder="$t('AnalysisType')"
                />
              </div>
              <div
                class="vx-col text-center lg:w-1/2 w-full"
                v-if="DoctorRequestModel.DocumentID == 3"
              >
                <v-select
                  :disabled="!editDoctorRequest"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full mb-10"
                  label="Name"
                  v-model="DoctorRequestModel.ReportTypeID"
                  :options="ReportTypes"
                  :reduce="(ID) => ID.ID"
                  :placeholder="$t('ReportType')"
                />
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col lg:w-3/4 w-full">
                <vs-input
                  :disabled="!editDoctorRequest"
                  class="w-full"
                  v-model="DoctorRequestModel.Description"
                  :placeholder="$t('Description')"
                />
              </div>

              <vs-button color="wight" :disabled="!editDoctorRequest">
                <feather-icon
                  style="margin-left: 10px; color: black"
                  icon="PlusIcon"
                  @click.stop="AddDoctorRequest()"
                  class="cursor-pointer"
                ></feather-icon>
              </vs-button>
            </div>

            <div
              style="text-decoration-line: underline"
              v-if="
                Model.PatientMedicalReportRequests != null &&
                Model.PatientMedicalReportRequests != undefined
              "
            >
              <div>
                <div
                  v-for="(item, index) in Model.PatientMedicalReportRequests"
                  :key="item.ID"
                >
                  <div class="vx-row">
                    <div class="vx-row lg:w-1/2 ml-1">
                      <h2
                        class="vx-col"
                        style="color: #454a62; font-size: 19px"
                      >
                        {{ item.DoctorRequestType.Name }}
                      </h2>
                      /
                      <h2
                        style="color: #032082; font-size: 19px"
                        class="vx-col"
                      >
                        {{ item.Staus.Name }}
                      </h2>
                    </div>
                    <vs-button
                      class="vx-col"
                      color="wight"
                      :disabled="!editDoctorRequest"
                    >
                      <feather-icon
                        style="margin-left: 10px; color: black"
                        icon="XIcon"
                        @click.stop="deleteDoctorRequest(index)"
                        class="cursor-pointer"
                      ></feather-icon>
                    </vs-button>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>

        <!-- prescription -->

        <div>
          <div class="vx-row w-full mb-3">
            <div style="color: #727272" class="vx-col lg:w-2/3">
              <span>{{ $t("Prescription") }}</span>
            </div>
            <div class="vx-col lg:w-1/4">
              <feather-icon
                :style="{ color: editPatientPrescription ? '#004477' : 'red' }"
                icon="EditIcon"
                @click.stop="editPatientPrescription = !editPatientPrescription"
                class="cursor-pointer"
              ></feather-icon>
              <feather-icon
                style="color: primary; margin-left: 10px"
                icon="SaveIcon"
                :style="{ color: editPatientPrescription ? 'red' : '#004477' }"
                @click.stop="editPatientPrescription = false"
                class="cursor-pointer"
                v-show="editPatientPrescription"
              >
              </feather-icon>
            </div>
          </div>

          <vx-card
            class="mb-3"
            height="70%"
            style="
              border-radius: 16px;
              opacity: 1;
              background: #fefefe 0% 0% no-repeat padding-box;
              width: 80%;
            "
          >
            <div class="vx-row">
              <div class="w-full">
                <label class="vs-input--label">{{ $t("Medication") }} </label>
                <!-- <v-select
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  class="w-full"
                  :disabled="!editPatientPrescription"
                  label="Name"
                  v-model="PrescriptionModel.Medicine"
                  :options="Medicins"
                  placeholder="Medicins"
                  :reduce="(obj) => obj"
                /> -->

                <v-select
                  :disabled="!editPatientPrescription"
                  label="name"
                  :filterable="false"
                  v-model="PrescriptionModel.Medicine"
                  :options="Medicins"
                  @search="onMedicinSearch"
                >
                  <template slot="no-options"> type to search </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.TradeName }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.TradeName }}
                    </div>
                  </template>
                </v-select>

                <vs-input
                  :disabled="!editPatientPrescription"
                  class="w-full lg:w-1/4 ml-1 mr-1"
                  :label="$t('MedicineName')"
                  v-if="
                    PrescriptionModel.Medicine && !PrescriptionModel.Medicine.ID
                  "
                  v-model="PrescriptionModel.MedicineName"
                />
              </div>
            </div>

            <div class="vx-row">
              <vs-input
                :disabled="!editPatientPrescription"
                class="w-full lg:w-1/4 ml-1 mr-1"
                :label="$t('Doosage')"
                v-model="PrescriptionModel.Dose"
              />
              <vs-input
                :disabled="!editPatientPrescription"
                type="number"
                class="w-full lg:w-1/4 ml-1 mr-1"
                :label="$t('DurationInDay')"
                v-model="PrescriptionModel.DurationInDay"
              />
              <vs-button color="wight" :disabled="!editPatientPrescription">
                <feather-icon
                  v-if="
                    PrescriptionModel.Medicine &&
                    ((PrescriptionModel.Medicine.ID == null &&
                      PrescriptionModel.MedicineName) ||
                      PrescriptionModel.Medicine.ID > 0) &&
                    PrescriptionModel.Dose &&
                    PrescriptionModel.DurationInDay
                  "
                  style="margin-left: 10px; color: black"
                  icon="PlusIcon"
                  @click.stop="AddPatientPrescription()"
                  class="cursor-pointer"
                ></feather-icon>
              </vs-button>
            </div>

            <div
              style="text-decoration-line: underline"
              v-if="
                Model.PatientReservationSessionPrescriptions != null &&
                Model.PatientReservationSessionPrescriptions != undefined &&
                Model.PatientReservationSessionPrescriptions.length > 0 &&
                Model.PatientReservationSessionPrescriptions[0]
                  .PatientReservatientSessionMedicals
              "
            >
              <div
                v-for="(item, index) in Model
                  .PatientReservationSessionPrescriptions[0]
                  .PatientReservatientSessionMedicals"
                :key="item.ID"
              >
                <div class="vx-row mt-5">
                  <h2
                    v-if="item.Medicine && item.Medicine.TradeName"
                    class="vx-col lg:w-1/4"
                    style="color: #454a62; font-size: 19px"
                  >
                    {{ item.Medicine.TradeName }}
                  </h2>
                  <h2
                    v-else
                    class="vx-col lg:w-1/4"
                    style="color: #454a62; font-size: 19px"
                  >
                    {{ item.MedicineName }}
                  </h2>
                  <h2
                    class="vx-col lg:w-1/4"
                    style="color: #454a62; font-size: 19px"
                  >
                    {{ item.Dose }}
                  </h2>
                  <h2
                    class="vx-col lg:w-1/4"
                    style="color: #454a62; font-size: 19px"
                  >
                    {{ item.DurationInDay }}
                  </h2>
                  <vs-button color="wight" :disabled="!editPatientPrescription">
                    <feather-icon
                      style="margin-left: 10px; color: black"
                      icon="XIcon"
                      @click.stop="deletePatientPrescription(item, index)"
                      class="cursor-pointer"
                    ></feather-icon>
                  </vs-button>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>

      <div class="vx-col lg:w-1/4">
        <vs-button
          v-if="Model.DoctorSession"
          class="vx-row w-full mt-3 customizer-btn text-sm bg-white text-dark"
          >{{ $t("Time") }}:
          <span class="text-primary">{{
            Model.DoctorSession.DateFrom.replace("T", " ")
          }}</span>
        </vs-button>

        <vs-button
          color="primary"
          @click="JoinMeeting()"
          class="vx-row w-full mt-3 customizer-btn text-white"
          v-if="!Model.DoctorJoined"
        >
          <div class="mt-1">
            {{ $t("Join") }}
          </div>
        </vs-button>

        <vs-button
          color="primary"
          @click="ScheduleAnotherAppintment()"
          class="vx-row w-full mt-3 customizer-btn text-white"
        >
          {{ $t("ScheduleAnotherAppintment") }}
        </vs-button>
      </div>
    </div>

    <vs-popup fullscreen title="" :active.sync="showDownloadReportsfield">
      <showDownloadReportsCom
        :patientDoc="patientDoc"
        v-if="showDownloadReportsfield"
        @closePop="showDownloadReportsfield = false"
      ></showDownloadReportsCom>
    </vs-popup>

    <vs-popup style="width: 100%" title="" :active.sync="showPatientSymptoms">
      <PatientsSymptomsDescription
        :PatientReservationID="PatientReservationID"
        v-if="showPatientSymptoms"
        @closePop="showPatientSymptoms = false"
      ></PatientsSymptomsDescription>
    </vs-popup>

    <vs-popup title="" :active.sync="ShowJoinPopup">
      <JoinSessionAlertPopup
        :sessionModel="sessionModel"
        v-if="ShowJoinPopup"
        @closePop="ShowJoinPopup = false"
        @joinSession="ChangeReservationJoin"
      >
      </JoinSessionAlertPopup>
    </vs-popup>
  </div>
</template>
<script>
import { domain, mainSetting } from "@/gloabelConstant.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import moduleDiagnosis from "@/store/settings/Diagnosis/moduleDiagnosis.js";
import moduleMedicine from "@/store/settings/Medicine/moduleMedicine.js";
import moduleDoctorRequestType from "@/store/settings/doctorRequestType/moduleDoctorRequestType.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import showDownloadReportsCom from "@/views/doctorProfile/pendingMedicalReportsDownload.vue";
import PatientsSymptomsDescription from "@/views/doctor/Patients/PatientsSymptomsDescription.vue";
import JoinSessionAlertPopup from "@/views/Patient/Reservation/JoinSessionAlertPopup.vue";
import imageLazy from "@/components/image_lazy.vue";
import moduleDocumentType from "@/store/settings/documentType/moduleDocumentType.js";
import moduleAnalysisType from "@/store/settings/analysisType/moduleAnalysisType.js";
import moduleReportType from "@/store/settings/reportType/moduleReportType.js";
import moduleScanType from "@/store/settings/scanType/moduleScanType.js";
export default {
  data() {
    return {
      //DateRequest:{},
      PrescriptionModel: {},
      baseURL: domain,
      uploadUrl: domain + "api/Common/UploadFile",
      showDownloadReportsfield: false,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      Model: {},
      patientDoc: {},
      DiagnosModel: {},
      PatientMedicalReportRequests: [],
      DoctorRequestModel: {},
      editDoctorNote: false,
      editPatientDiagnosis: false,
      editPatientPrescription: false,
      editDoctorRequest: false,
      editPatientDiagnosisNote: false,
      showPatientSymptoms: false,
      PatientReservationID: 0,
      ShowJoinPopup: false,
      sessionModel: {},
    };
  },

  components: {
    showDownloadReportsCom,
    PatientsSymptomsDescription,
    JoinSessionAlertPopup,
    imageLazy,
  },
  computed: {
    Diagnosis() {
      return this.$store.state.DiagnosisList.Diagnosis;
    },
    Medicins() {
      var Medicines = this.$store.state.medicineList.Medicines;
      Medicines.push({ ID: null, TradeName: "Other" });
      return Medicines;
    },
    DoctorRequestTypes() {
      return this.$store.state.DoctorRequestTypeList.DoctorRequestTypes;
    },
    ScanTypes() {
      return this.$store.state.ScanTypeList.ScanTypes;
    },

    DocumentTypes() {
      return this.$store.state.DocumentTypeList.DocumentTypes;
    },

    ReportTypes() {
      return this.$store.state.ReportTypeList.ReportTypes;
    },
    AnalysisTypes() {
      return this.$store.state.AnalysisTypeList.AnalysisTypes;
    },
  },
  methods: {
    onMedicinSearch(search, loading) {
      debugger;
      this.$store.dispatch("medicineList/SearchMedicines", search);
    },
    ChangeReservationJoin(reservationId) {
      this.$store
        .dispatch("patientList/ChangeReservationJoin", reservationId)
        .then((res) => {
          this.getSession();
        });
    },

    AddPatientPrescription() {
      debugger;
      this.$vs.loading();
      if (
        !this.Model.PatientReservationSessionPrescriptions ||
        this.Model.PatientReservationSessionPrescriptions.length == 0
      ) {
        this.Model.PatientReservationSessionPrescriptions = [];
        this.Model.PatientReservationSessionPrescriptions.push({
          PatientReservatientSessionMedicals: [],
        });
      }
      if (
        !this.Model.PatientReservationSessionPrescriptions[0]
          .PatientReservatientSessionMedicals
      )
        this.Model.PatientReservationSessionPrescriptions[0].PatientReservatientSessionMedicals =
          [];

      this.PrescriptionModel.PatientReservedSessionID = this.Model.ID;

      if (this.PrescriptionModel.Medicine.ID == null)
        this.PrescriptionModel.Medicine = {};
      this.$store
        .dispatch("DoctorList/AddMedicalPrescription", this.PrescriptionModel)
        .then((res) => {
          this.$vs.loading.close();
          window.showSuccess();
          this.PrescriptionModel.ID = res.data.Data.ID;
          this.Model.PatientReservationSessionPrescriptions[0].PatientReservatientSessionMedicals.push(
            this.PrescriptionModel
          );
          this.PrescriptionModel = {};
        });
    },

    SavePrescription() {
      this.$vs.loading();
      this.$store
        .dispatch(
          "DoctorList/CompletePatientReservation",
          this.$route.params.PatientReservationID
        )
        .then((res) => {
          this.$vs.notify({
            title: this.$t("success"),
            text: res.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
          this.$vs.loading.close();
          this.getAppointmentSessions(false);
        })
        .catch((err) => {
          this.$vs.loading.close();
          debugger;
          this.$vs.notify({
            title: this.$t("Error"),
            text: err.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    deletePatientPrescription(medicine, index) {
      debugger;
      this.$store
        .dispatch("DoctorList/DeleteMedicalPrescription", medicine)
        .then((res) => {
          this.Model.PatientReservationSessionPrescriptions[0].PatientReservatientSessionMedicals.splice(
            index,
            1
          );
        });
    },

    createSeeionMeeting(sesstionId) {
      this.$store
        .dispatch("auth/CreateMeetingSession", sesstionId)
        .then((res) => {
          if (res.status == 200) {
            this.sessionModel.JoinUrl = res.data.Data.ModeratorUrl;
            this.ShowJoinPopup = true;
          }
        });
    },
    JoinMeeting() {
      debugger;
      this.sessionModel.ID = this.Model.ID;
      //this.sessionModel.JoinUrl = this.Model.ModeratorUrl;
      // "2021-12-31T17:50:00"
      var DateNow = new Date();
      var DateStart = new Date(this.Model.DoctorSession.DateFrom);
      const milliseconds = DateStart - DateNow;

      var Minutes = Math.floor(milliseconds / 60000);
      // / 36e5
      debugger;

      if (Minutes <= 5) {
        this.createSeeionMeeting(this.Model.ID);
      } else {
        this.$vs.notify({
          color: "danger",
          position: "right-bottom",
          text: "this meeting can't start now",
        });
      }
    },
    back() {
      this.$router.go(-1);
    },
    showDownloadReports(PatientID, DoctorID) {
      debugger;
      this.patientDoc.PatientID = PatientID;
      this.patientDoc.DoctorID = DoctorID;
      this.showDownloadReportsfield = true;
    },
    showPatientHistory(PatientID) {
      debugger;
      this.$router.push({
        name: "PatientMedicalHistorySession",
        params: { ID: PatientID },
      });
    },
    AddPatientDiagnosis() {
      debugger;
      var model = {};
      model.DiagnosisID = this.DiagnosModel.Diagnos.ID;
      model.Diagnosis = this.DiagnosModel.Diagnos;
      model.PatientReservationSessionID = this.Model.ID;
      this.Model.PatientReservationSessionDiagnosis.push(model);
      this.DiagnosModel = {};
    },
    AddDoctorRequest() {
      debugger;
      var Staus = {};
      Staus.Name = "Pending";
      var model = {};
      var vm = this;
      model.DoctorRequestTypeID = this.DoctorRequestModel.DocumentID;
      model.ScanTypeID = this.DoctorRequestModel.ScanTypeID;
      model.ReportTypeID = this.DoctorRequestModel.ReportTypeID;
      model.AnalysisTypeID = this.DoctorRequestModel.AnalysisTypeID;
      model.Description = this.DoctorRequestModel.Description;
      model.DoctorID = this.Model.DoctorID;
      model.StausID = 1; //pending
      model.DoctorRequestType = this.DocumentTypes.find(
        (obj) => obj.ID == vm.DoctorRequestModel.DocumentID
      );
      model.Staus = Staus;
      model.Date = new Date();

      model.PatientReservationSessionID = this.Model.ID;
      this.Model.PatientMedicalReportRequests.push(model);
      this.DoctorRequestModel = {};
    },
    deleteDoctorRequest(index) {
      // const ItemIndex = this.Model.PatientMedicalReportRequests.findIndex(
      //   (p) => p.DoctorRequestTypeID == data.DoctorRequestTypeID
      // );
      this.Model.PatientMedicalReportRequests.splice(index, 1);
    },
    deletePatientDiagnosis(index) {
      // debugger;
      // const ItemIndex = this.Model.PatientReservationSessionDiagnosis.findIndex(
      //   (p) => p.DiagnosisID == data.DiagnosisID
      // );

      this.Model.PatientReservationSessionDiagnosis.splice(index, 1);
    },

    openPatientsSymptoms(ID) {
      this.showPatientSymptoms = true;
      this.PatientReservationID = ID;
    },
    SaveDoctorNotePatientDiagnosisNote() {
      debugger;
      this.$vs.loading();
      var model = {};
      model.PatientID = this.Model.PatientID;
      model.DoctorSessionID = this.Model.DoctorSessionID;
      model.DoctorID = this.Model.DoctorID;
      model.PaymentStatusID = this.Model.PaymentStatusID;
      model.PrescriptionNote = this.Model.PrescriptionNote;
      model.DoctorComment = this.Model.DoctorComment;
      model.SymptomNote = this.Model.SymptomNote;
      model.RequestedReportNote = this.Model.RequestedReportNote;
      model.DiagnosisNote = this.Model.DiagnosisNote;
      model.StatusID = this.Model.StatusID;
      model.ID = this.Model.ID;

      this.$store
        .dispatch("patientList/UpdatePatientReservationSession", model)
        .then(() => {
          this.$vs.loading.close();
          window.showSuccess();
          this.editDoctorNote = false;
          this.editPatientDiagnosisNote = false;
        })
        .catch(() => {
          this.$vs.loading.close();
        });
      this.editDoctorNote = false;
      this.editPatientDiagnosisNote = false;
    },
    SavePatientDiagnosis() {
      this.$vs.loading();
      this.$store
        .dispatch(
          "patientList/UpdateListPatientReservationSessionDiagnosis",
          this.Model.PatientReservationSessionDiagnosis
        )
        .then(() => {
          this.$vs.loading.close();
          window.showSuccess();
          this.editPatientDiagnosis = false;
        })
        .catch(() => {
          this.$vs.loading.close();
        });
      this.editPatientDiagnosis = false;
    },
    SaveDoctorRequest() {
      debugger;
      this.$vs.loading();
      var model = {};
      model.PatientReservationID = parseInt(
        this.$route.params.PatientReservationID
      );
      model.PatientMedicalReportRequestDTOs =
        this.Model.PatientMedicalReportRequests;
      this.$store
        .dispatch("patientList/UpdateListPatientMedicalReportRequest", model)
        .then(() => {
          this.$vs.loading.close();
          window.showSuccess();
          this.editDoctorRequest = false;
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },

    getSession() {
      debugger;
      this.$vs.loading();
      debugger;
      var DateRequest = {};
      DateRequest.sessionID = this.$route.params.PatientReservationID;
      if (
        this.$route.params.IsDoctorRequest != null &&
        this.$route.params.IsDoctorRequest != undefined
      )
        DateRequest.IsDoctorRequest = this.$route.params.IsDoctorRequest;
      else {
        DateRequest.IsDoctorRequest = true;
      }
      this.$store
        .dispatch(
          "DoctorList/GetPatientReservationSessionViaDoctor",
          DateRequest
        )
        .then((res) => {
          debugger;
          this.Model = res.data.Data;
          debugger;
          if (this.Model.PatientMedicalReportRequests != null) {
            this.PatientMedicalReportRequests =
              this.Model.PatientMedicalReportRequests;
          }
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    ScheduleAnotherAppintment() {
      const sessionID = this.$route.params.PatientReservationID;
      this.$store
        .dispatch("patientList/ScheduleAnotherAppintment", sessionID)
        .then((res) => {
          this.$vs.notify({
            title: "successfully",
            text: res.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
    if (!moduleScanType.isRegistered) {
      this.$store.registerModule("ScanTypeList", moduleScanType);
      moduleScanType.isRegistered = true;
    }

    this.$store.dispatch("ScanTypeList/GetAllScanTypes");

    if (!moduleReportType.isRegistered) {
      this.$store.registerModule("ReportTypeList", moduleReportType);
      moduleReportType.isRegistered = true;
    }

    this.$store.dispatch("ReportTypeList/GetAllReportTypes");
    if (!moduleAnalysisType.isRegistered) {
      this.$store.registerModule("AnalysisTypeList", moduleAnalysisType);
      moduleAnalysisType.isRegistered = true;
    }

    this.$store.dispatch("AnalysisTypeList/GetAllAnalysisTypes");
    if (!moduleDocumentType.isRegistered) {
      this.$store.registerModule("DocumentTypeList", moduleDocumentType);
      moduleDocumentType.isRegistered = true;
    }

    this.$store.dispatch("DocumentTypeList/GetAllDocumentTypes");

    if (!moduleMedicine.isRegistered) {
      this.$store.registerModule("medicineList", moduleMedicine);
      moduleMedicine.isRegistered = true;
    }
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

    if (!moduleDoctorRequestType.isRegistered) {
      this.$store.registerModule(
        "DoctorRequestTypeList",
        moduleDoctorRequestType
      );
      moduleDoctorRequestType.isRegistered = true;
    }
    debugger;
    this.$store.dispatch("DoctorRequestTypeList/GetAllDoctorRequestTypes");
    if (!moduleDiagnosis.isRegistered) {
      this.$store.registerModule("DiagnosisList", moduleDiagnosis);
      moduleDiagnosis.isRegistered = true;
    }

    //this.$store.dispatch("medicineList/GetAllMedicines");
    this.$store.dispatch("medicineList/SearchMedicines", "");
    this.$store.dispatch("DiagnosisList/GetAllDiagnosis");
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    this.getSession();
  },
};
</script>
<style>
#sessionDetail .vs-button-text {
  margin: auto;
  width: 100%;
}

#sessionDetail table {
  border-collapse: collapse;
  border-radius: 2em;
  overflow: hidden;
}

#sessionDetail th,
td {
  border: 1px solid rgb(255, 255, 255);
  border-collapse: collapse;
}

#sessionDetail .mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}

#sessionDetail .login-tabs-container {
  min-height: 505px;
}

#sessionDetail .con-tab {
  padding-bottom: 14px;
}

#sessionDetail .con-slot-tabs {
  margin-top: 1rem;
}

.background {
  color: rgb(255, 9, 9);
}

#sessionDetail .stack-top {
  z-index: 9;
  border-radius: 5px;
  background: #202020;
}

#sessionDetail .head {
  color: white;
  text-align: center;
  margin-top: 20px;
}
</style>
