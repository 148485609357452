<template>

  <div class="container justify-center items-center" id="symptoms" style=" width: 100%;">
       <div class="MyPopClose">
                <feather-icon
                  icon="XIcon"
                  style="color:white"
                  svgClasses="w-8 h-8 hover:text-danger   stroke-current"
                  @click.stop="$emit('closePop')"
                />
              </div>
      <div style="">
        <img width="90%" height="50%" class="TextLogo justify-center items-center" alt="" src="@/assets/images/footer_logo.png" />

       </div>
       <div >
       <div style="width:80% ;margin:auto">
         <p class="P-Text mt-8 " >{{$t("PatientSymptoms")}} </p>
       </div>

       <vx-card style="width:80% ; margin:auto">
            <article class="text-1xl " style="color:black" v-if="Model.SymptomNote">
                     {{Model.SymptomNote}}
            </article>
            <article class="text-1xl " style="color:black"  v-else>
                    {{$t('PatientSymptomNote')}}
            </article>
          </vx-card>
       </div>
  </div>


  </template>
  <script>
  import moduleDoctor from "@/store/doctor/moduleDoctor.js";
  import { domain } from "@/gloabelConstant.js";

  export default {
       data() {
      return {
        baseURL: domain,
        Model: {}
      };
    },
    props:{
      PatientReservationID:{
           type: Number,
        required: true
      }
    },
      created() {

      if (!moduleDoctor.isRegistered) {
        this.$store.registerModule("DoctorList", moduleDoctor);
        moduleDoctor.isRegistered = true;
          }
            debugger
          var model={};
          model.PatientReservationSessionID = this.PatientReservationID;
         this.$store
            .dispatch("DoctorList/getPatientReservation", model)
            .then((res) => {
              if (res.status == 200) {
                     debugger;
                this.Model = res.data.Data[0];
                console.log(this.Model.SymptomNote);
              }
            })
            .catch(() => {
              this.$vs.loading.close();
            });
    },
  }
  </script>
  <style >
  .con-vs-popup .vs-popup
  {
    box-shadow:unset
  }


  </style>
  <style>

  #symptoms .container
  {

  background: transparent radial-gradient(closest-side at 50% 50%, #004477 0%, #004477 100%) 0% 0% no-repeat padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px;
  opacity: 1;
  text-align: center;
   width: 70%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  }
  .logo{


  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  display: inline-block;

   width: 12REM; padding: .5rem;

  }
  .TextLogo{
    letter-spacing: 0px;
    font-size: 1.8rem;
    font-weight:bold;


  }
  .P-Text{
   color: #FFFFFF;
  margin-bottom:.5rem;
  font-size: 1.2rem;
   letter-spacing: 0px;
   text-align: initial;

  opacity: 1;


  }
  .text-1xl{
       color: #393939;

  font-size: 1rem;
   letter-spacing: 0px;

  opacity: .5;

  }


  </style>
